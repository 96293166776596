<template>
  <v-app>
    <div class="primary lighten-1 fill-height">
      <SingleAppBar title="กล่องข้อความ" :backward="true" />
      <v-main>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-card color="primary lighten-4">
                <v-card-text
                  style="background-color: white"
                  class="text-center"
                >
                  <v-card elevation="0">
                    <v-img
                      class="white--text align-end"
                      height="200px"
                      :src="model.img"
                    ></v-img>
                  </v-card>
                </v-card-text>

                <v-card-text style="background-color: white">
                  <v-list two-line>
                    <v-list-item style="padding: 0px">
                      <v-list-item-avatar size="40" color="primary lighten-1"
                        ><v-icon size="24" color="primary lighten-3"
                          >mdi-email-open</v-icon
                        ></v-list-item-avatar
                      >
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="model.title"
                        ></v-list-item-title>

                        <v-list-item-subtitle
                          v-text="model.body"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
             
                  <v-card outlined>
                    <v-card-text
                      class="text-left text--secondary text-body-normal"
                    >
                      <div>จาก: {{ model.createByUserName }}</div>
                      <div>วันที่: {{$dayjs(new Date(model.createDate) ).locale('th').format('DD MMMM YYYY HH:mm')  }}</div>
                    </v-card-text>
                  </v-card>
                  <div
                    class="pt-5 text-left text--primary text--darken-4 text-body-normal"
                  >
                    {{ model.body }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
// @ is an alias to /src
import SingleAppBar from "@/components/common/SingleAppBar";

export default {
  name: "Home",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      model: null,
    };
  },
  components: {
    SingleAppBar,
    //LangList
  },
  methods: {
    fetchData() {
          if(this.item){
      this.model = this.item;
      this.$offlineStorage.set("currentAnnounce",this.item);
      // console.log(this.item)
       //console.log('savedata')
      }else{
      this.model = this.$offlineStorage.get("currentAnnounce");
     // console.log("savedata");
      //console.log(this.model);
       }
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>


<style lang="scss" scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
.v-avatar--offset {
  top: -25px;
  position: relative;
}
</style>